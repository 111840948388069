import React from 'react';
import useMarketingModule from '@hooks/useMarketingModuleLogic';
import BaseMarketingModule from '@components/MarketingModule/BaseMarketingModule';
import withPromoLogic, { PromoLogicProps } from '../CaptureEmail/withPromoLogic';

function MarketingModuleWithOverlappingImage({
  bfCMPromoFlag,
  handleClick,
  promoConfig,
}: PromoLogicProps) {
  const { currentStep, email, handleCTAClick, handleSubmit, setEmail } =
    useMarketingModule(handleClick);

  const layoutConfig = {
    containerClasses: `flex flex-col items-center justify-center pb-10 pt-172px relative rounded-xl w-full d:flex-row d:pb-50px d:pt-58px d:h-370 ${
      bfCMPromoFlag === 'early-access' || bfCMPromoFlag === 'main-promo'
        ? 'bg-rp-shadow'
        : 'bg-rp-navy'
    }`,

    imageWrapperClasses:
      'absolute h-180px -top-32px w-233px d:relative d:top-0 d:w-544px d:h-420px',

    textWrapperClasses:
      'flex flex-col max-w-549 ml-0 space-y-6 px-6 text-white d:px-0 d:ml-11 d:pr-11 w-full',

    titleClasses: `leading-normal text-28 max-w-295 d:text-40px d:w-full d:max-w-none ${
      bfCMPromoFlag === 'early-access' || bfCMPromoFlag === 'main-promo'
        ? 'font-rp-pn-regular'
        : 'font-rp-gelica-light'
    }`,
  };

  return (
    <BaseMarketingModule
      promoConfig={promoConfig}
      currentStep={currentStep}
      email={email}
      handleCTAClick={handleCTAClick}
      handleSubmit={handleSubmit}
      setEmail={setEmail}
      layoutConfig={layoutConfig}
    />
  );
}

export default withPromoLogic(MarketingModuleWithOverlappingImage, 'MarketingModule');
