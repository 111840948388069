import React from 'react';
import ImageFallback from '@components/common/Image/ImageFallback';
import { getCdnImageUrl } from '@utils/cloudflare';
import Link from 'next/link';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { PromoConfig } from '@hooks/useEmailCaptureConfig';

type BaseMarketingModuleProps = {
  promoConfig: PromoConfig;
  currentStep: number;
  email: string;
  handleCTAClick: () => void;
  handleSubmit: () => void;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  layoutConfig: {
    containerClasses: string;
    imageWrapperClasses: string;
    textWrapperClasses: string;
    titleClasses: string;
  };
};

export default function BaseMarketingModule({
  promoConfig,
  currentStep,
  email,
  handleCTAClick,
  handleSubmit,
  setEmail,
  layoutConfig,
}: BaseMarketingModuleProps) {
  return (
    <div className={layoutConfig.containerClasses}>
      <div className={layoutConfig.imageWrapperClasses}>
        <ImageFallback
          className="rounded-xl"
          src={getCdnImageUrl(
            500,
            promoConfig?.image || 'home-v2/marketing_module_v2.png',
            'https://static-assets.resortpass.com/assets/',
            100,
          )}
          blurDataUrl={getCdnImageUrl(
            50,
            promoConfig?.image || 'home-v2/marketing_module_v2.png',
            'https://static-assets.resortpass.com/assets/',
            10,
          )}
          alt={promoConfig?.pre_submission_title || ''}
          title={promoConfig?.pre_submission_title || ''}
          loading="eager"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </div>

      <div className={layoutConfig.textWrapperClasses}>
        <h2 className={layoutConfig.titleClasses}>
          {currentStep === 1 && promoConfig?.pre_submission_title}
          {currentStep === 2 && promoConfig?.post_submission_title}
          {currentStep === 3 && promoConfig?.already_submitted_title}
        </h2>

        <p className="text-base">
          {currentStep === 1 && promoConfig?.pre_submission_subtitle}
          {currentStep === 2 && promoConfig?.post_submission_subtitle}
          {currentStep === 3 && promoConfig?.already_submitted_subtitle}

          {currentStep === 1 ? (
            <>
              {' '}
              <Link href="/terms-conditions" passHref>
                <a target="_blank">Terms and Conditions apply.</a>
              </Link>
            </>
          ) : null}
        </p>

        {currentStep === 1 && (
          <div className="bg-white flex justify-between rounded-4 border border-rp-gray-divider py-0.5 pr-0.5 pl-3.5 d:w-90">
            <input
              className="mr-4 outline-none text-base text-rp-disabled w-full"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address *"
            />

            <button
              type="button"
              onClick={handleSubmit}
              className="bg-rp-primary flex-shrink-0 font-rp-pn-semi-bold h-11 text-base rounded-4 w-87px"
            >
              {promoConfig?.pre_submission_button_label}
            </button>
          </div>
        )}

        {currentStep === 3 && promoConfig?.already_submitted_button_label && (
          <ButtonPrimary onClick={handleCTAClick}>
            {promoConfig?.already_submitted_button_label}
          </ButtonPrimary>
        )}
      </div>
    </div>
  );
}
